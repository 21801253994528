<template>
  <v-container fluid class="text-center">
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark color="secondary" elevation="2">
        <v-toolbar-title> Usuarios </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small @click="dialogs.new = true">
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
          Actualizar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          class="mx-2"
          single-line
          hide-details
          maxlength="150"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
        :items-per-page="10"
        item-key="id"
        class="elevation-1"
        dense
      >
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)"
                >mdi-pencil</v-icon
              ></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'companies')"
                >mdi-domain</v-icon
              ></template
            ><span>Asignar empresas</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'roles')"
                >mdi-shield-account</v-icon
              ></template
            ><span>Actualizar rol</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="asignResponses(item, 'response')"
                >mdi-account-box</v-icon
              ></template
            ><span>Asignar responsabilidades</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="warning" dark v-bind="attrs" v-on="on" :disabled="loading" @click="viewReset(item)"
                >mdi-key</v-icon
              ></template
            ><span>Actualizar contraseña</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idusuario)"
                >mdi-delete</v-icon
              ></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`item.usuarios_roles[0].rol.rol`]="{ item }">
          <v-btn v-if="item.usuarios_roles[0].rol.rol === 'administrador'" x-small elevation="0" color="error" rounded dark>{{
            item.usuarios_roles[0].rol.rol
          }}</v-btn>
          <v-btn v-else x-small elevation="0" color="blue-grey" rounded dark>{{ item.usuarios_roles[0].rol.rol }}</v-btn>
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nuevo usuario</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.email" label="Correo" :rules="rules.email" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.new.password"
                    label="Contraseña"
                    :type="show ? 'text' : 'password'"
                    :rules="rules.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.new.apellido_paterno"
                    label="Apellido Paterno"
                    :rules="rules.apellido_paterno"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.new.apellido_materno"
                    label="Apellido Materno"
                    :rules="rules.apellido_materno"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="forms.new.telefono" label="Teléfono" :rules="rules.telefono" :disabled="loading"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="forms.new.empresa" label="Empresa" :rules="rules.empresa" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.new.sector_profesional"
                    :items="catalogues.sectores_profesionales"
                    item-text="nombre"
                    item-value="idsector_profesional"
                    label="Sector profesional"
                    :rules="rules.sector_profesional"
                    :disabled="loading"
                    required
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar usuario</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.email" label="Correo" disabled required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.password"
                    label="Contraseña"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    disabled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.apellido_paterno"
                    label="Apellido Paterno"
                    :rules="rules.apellido_paterno"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.apellido_materno"
                    label="Apellido Materno"
                    :rules="rules.apellido_materno"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="forms.update.telefono" label="Teléfono" :rules="rules.telefono" :disabled="loading"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="forms.update.empresa"
                    label="Empresa"
                    :rules="rules.empresa"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.update.sector_profesional"
                    :items="catalogues.sectores_profesionales"
                    item-text="nombre"
                    item-value="idsector_profesional"
                    label="Sector profesional"
                    :rules="rules.sector_profesional"
                    :disabled="loading"
                    required
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.companies" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Asignar empresas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.update.empresas"
                    :items="catalogues.empresas"
                    item-text="nombre"
                    item-value="idempresa"
                    label="Empresas"
                    multiple
                    chips
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.roles" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar rol</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="forms.update.rol"
                    :items="catalogues.roles"
                    item-text="rol"
                    item-value="idrol"
                    label="Rol"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.reset" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar contraseña</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="resetPassword()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formReset" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.reset.email" label="Correo" disabled required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.reset.password"
                    label="Contraseña"
                    :type="show ? 'text' : 'password'"
                    :rules="rules.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    :disabled="loading"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="forms.reset.confirmPassword"
                    label="Confirmar contraseña"
                    :type="show ? 'text' : 'password'"
                    :rules="rules.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el usuario?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
    <v-dialog v-model="dialogs.response" persistent max-width="600">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Asignar responsabilidades</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" :disabled="!disabledResponsibilitiesButton" @click="assignResponsibilities()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" class="mt-5 pb-0">
              <h2 class="">
                <strong
                  >{{ forms.responsibilities.detalles_usuarios.nombre }} {{ forms.responsibilities.detalles_usuarios.apellido_paterno }}</strong
                >
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Empresa"
                v-model="forms.responsibilities.companySelected"
                :items="forms.responsibilities.companies"
                item-value="idempresa"
                item-text="nombre"
                @change="searchSucursal"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Sucursales"
                :items="forms.responsibilities.locations"
                v-model="selectedLocations"
                @change="searchResponsibilitiesByLocation"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Fuentes de emisión"
                :items="fuentesEmision"
                item-text="nombre"
                item-value="idfuente_emision"
                v-model="selectedEmisions"
                class="fixed-height"
                style="min-width: 265px; max-width: 290px; max-height: 30px"
                @change="pushSelectedEmisions()"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="selectedEmisions.length === 1">{{ item.nombre }}</span>

                  <span v-else-if="index === selectedEmisions.length - 1">{{ item.nombre }} ... y {{ selectedEmisions.length - 1 }} más.</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="text-center">
              <v-radio-group row v-model="forms.responsibilities.type">
                <v-radio label="Anual" value="0" />
                <v-radio label="Mensual" value="1" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import services from '@/utils/services';

export default {
  name: 'user',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    companyId: '',
    headers: [
      { text: 'Correo', align: 'left', sortable: false, value: 'email' },
      { text: 'Nombre', align: 'left', sortable: false, value: 'detalles_usuarios[0].nombre' },
      { text: 'Apellido Paterno', align: 'left', sortable: false, value: 'detalles_usuarios[0].apellido_paterno' },
      { text: 'Apellido Materno', align: 'left', sortable: false, value: 'detalles_usuarios[0].apellido_materno' },
      { text: 'Empresas asignadas', align: 'left', sortable: false, value: 'usuarios_empresas.length' },
      //{ text: 'Rol', align: 'left', sortable: false, value: 'usuarios_roles[0].rol.rol' },
      { text: 'Rol', align: 'left', sortable: false, value: 'usuarios_roles[0]?.rol?.rol' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' }
    ],
    items: [],
    fuentesEmision: [],
    selected: [],
    deleted: null,
    show: false,
    catalogues: {
      sectores_profesionales: [],
      empresas: [],
      roles: []
    },
    selectedEmisions: [],
    selectedLocations: '',
    locationsForm: [],
    emisionsForm: [],
    emissionsAlreadyAssigned: false,
    forms: {
      new: {
        email: '',
        password: '',
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        telefono: '',
        empresa: '',
        sector_profesional: ''
      },
      update: {
        idusuario: '',
        email: '',
        password: '',
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        telefono: '',
        empresa: '',
        sector_profesional: ''
      },
      reset: {
        idusuario: '',
        password: '',
        confirmPassword: ''
      },
      responsibilities: {
        companies: [],
        locations: [],
        emisions: [],
        companySelected: null,
        type: '0',
        detalles_usuarios: {
          nombre: null,
          apellido_paterno: null,
          apellido_materno: null
        }
      }
    },
    rules: {
      email: [
        (v) => !!v || 'El campo es requerido',
        (v) => (v && v.length <= 320) || 'El campo excede la longitud máxima',
        (v) =>
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'El campo es inválido'
      ],
      password: [
        (v) => !!v || 'El campo es requerido',
        (v) =>
          (v && v.length >= 8) || 'La contraseña debe contener mínimo 8 caracteres alfanuméricos, incluyendo al menos una letra y un número',
        (v) => (v && v.length <= 60) || 'La contraseña excede la longitud máxima',
        (v) =>
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) ||
          'La contraseña debe contener mínimo 8 caracteres, incluyendo al menos una letra y un número'
      ],
      nombre: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 100) || 'El campo excede la longitud máxima'],
      apellido_paterno: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 100) || 'El campo excede la longitud máxima'],
      apellido_materno: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 100) || 'El campo excede la longitud máxima'],
      telefono: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 15) || 'El campo excede la longitud máxima'],
      empresa: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 100) || 'El campo excede la longitud máxima'],
      sector_profesional: [(v) => !!v || 'El campo es requerido']
    },
    dialogs: {
      new: false,
      update: false,
      companies: false,
      roles: false,
      reset: false,
      remove: false,
      response: false
    }
  }),
  mounted() {
    this.refresh();
  },

  computed: {
    disabledResponsibilitiesButton() {
      return (
        this.forms.responsibilities.companySelected &&
        this.selectedLocations.length > 0 &&
        this.selectedEmisions.length > 0 &&
        this.forms.responsibilities.type !== ''
      );
    }
  },
  methods: {
    async refresh() {
      this.loading = true;
      const sector = await this.axios.get(services.routes.professionalSector);
      if (sector.data.data) {
        this.catalogues.sectores_profesionales = sector.data.data;
      }
      const company = await this.axios.get(services.routes.company);
      if (company.data.data) {
        this.catalogues.empresas = company.data.data;
      }
      const role = await this.axios.get(services.routes.role);
      if (role.data.data) {
        this.catalogues.roles = role.data.data;
      }
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.items = response.data.data;
          console.log('Usuarios', response.data);
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          this.forms.new[key] = '';
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update || this.dialogs.companies || this.dialogs.roles) {
        this.$refs.formUpdate.reset();
        for (let key in this.forms.update) {
          this.forms.update[key] = '';
        }
        delete this.forms.update.empresas;
        delete this.forms.update.rol;
        this.dialogs.update = false;
        this.dialogs.companies = false;
        this.dialogs.roles = false;
      } else if (this.dialogs.reset) {
        this.$refs.formReset.reset();
        for (let key in this.forms.reset) {
          this.forms.reset[key] = '';
        }
        this.dialogs.reset = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      } else if (this.dialogs.response) {
        this.forms.responsibilities = {
          companies: [],
          locations: [],
          emisions: [],
          type: '0',
          companySelected: null,
          detalles_usuarios: {
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null
          }
        };
        this.selectedEmisions = [];
        this.selectedLocations = [];
        this.fuentesEmision = [];
        this.locationsForm = [];
        this.emisionsForm = [];
        this.dialogs.response = false;
        this.emissionsAlreadyAssigned = false;
      }

      this.show = false;
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.user, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = 'Usuario creado exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = 'La información es incorrecta';
      }
    },
    view(data, type) {
      this.forms.update = {
        idusuario: data.idusuario,
        email: data.email,
        password: '********************',
        nombre: data.detalles_usuarios[0].nombre,
        apellido_paterno: data.detalles_usuarios[0].apellido_paterno,
        apellido_materno: data.detalles_usuarios[0].apellido_materno,
        telefono: data.detalles_usuarios[0].telefono,
        empresa: data.detalles_usuarios[0].empresa,
        sector_profesional: data.detalles_usuarios[0].sector_profesional ? data.detalles_usuarios[0].sector_profesional.idsector_profesional : ''
      };
      if (type === 'companies') {
        this.forms.update.empresas = [];
        for (let i = 0; i < data.usuarios_empresas.length; i++) {
          this.forms.update.empresas.push(data.usuarios_empresas[i].empresa.idempresa);
        }
        this.dialogs.companies = true;
      } else if (type === 'roles') {
        this.forms.update.rol = null;
        if (data.usuarios_roles.length) {
          this.forms.update.rol = data.usuarios_roles[0].rol.idrol;
        }
        this.dialogs.roles = true;
      } else {
        this.dialogs.update = true;
      }
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.user, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = 'Usuario actualizado exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = 'La información es incorrecta';
      }
    },
    viewReset(data) {
      this.forms.reset = {
        idusuario: data.idusuario,
        email: data.email,
        password: ''
      };
      this.dialogs.reset = true;
    },
    resetPassword() {
      if (this.$refs.formReset.validate()) {
        if (this.forms.reset.password === this.forms.reset.confirmPassword) {
          this.loading = true;
          let data = JSON.parse(JSON.stringify(this.forms.reset));
          delete data.confirmPassword;
          this.axios
            .put(services.routes.user + '/password', { data })
            .then((response) => {
              this.cancel();
              this.refresh();
              this.success = true;
              this.msgSuccess = 'Contraseña actualizada exitosamente';
            })
            .catch((error) => {
              this.error = true;
              this.msgError = error.response.data.message || 'Servicio no disponible';
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.error = true;
          this.msgError = 'Las contraseñas no coinciden';
        }
      } else {
        this.error = true;
        this.msgError = 'La información es incorrecta';
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        let id = this.deleted;
        this.axios
          .delete(services.routes.user, { data: { data: { idusuario: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = 'Usuario eliminado exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    asignResponses(item, type) {
      console.log(item);
      let empresas = item.usuarios_empresas;

      let arrayEmpresas = empresas.map((usuarioEmpresa) => usuarioEmpresa.empresa);
      this.forms.responsibilities.companies = arrayEmpresas;
      // this.forms.responsibilities.companySelected = arrayEmpresas[0]
      this.forms.responsibilities.detalles_usuarios = { ...item.detalles_usuarios[0], idUsuario: item.idusuario };
      this.dialogs.response = true;
    },

    async assignResponsibilities() {
      this.loading = true;
      this.forms.responsibilities.emisions = this.emisionsForm;
      const responsibilities = {
        idEmpresa: this.forms.responsibilities.companySelected,
        fuentesDeEmision: this.forms.responsibilities.emisions,
        idUsuario: this.forms.responsibilities.detalles_usuarios.idUsuario,
        locacion: this.selectedLocations[0],
        // locations: this.locationsForm, DESCOMENTAR ESTA LINEA JUNTO CON LA FUNCION PUSH SELECTEDLOCATIONS EN DADO CASO QUE SE QUIERA MANDAR UN ARREGLO DE LOCACIONES
        tipo: this.forms.responsibilities.type
      };

      let deletePromise = Promise.resolve();
      if (this.emissionsAlreadyAssigned) {
        console.log('Si hay registros asignados');

        // Si hay registros asignados, se ejecuta la eliminación
        deletePromise = this.axios
          .delete(services.routes.responsibilities, {
            params: {
              idUsuario: this.forms.responsibilities.detalles_usuarios.idUsuario,
              idEmpresa: this.forms.responsibilities.companySelected,
              locacion: this.selectedLocations[0]
            }
          })
          .then((response) => {
            console.log('Se eliminaron registros', response.data);
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response?.data?.message || 'Error al eliminar responsabilidades previas.';
            throw error;
          });
      }

      // Encadenamos la ejecución del post después de que se resuelva el deletePromise
      deletePromise
        .then(() => {
          return this.axios.post(services.routes.responsibilities, responsibilities);
        })
        .then((response) => {
          this.cancel();
          this.refresh();
          this.success = true;
          this.msgSuccess = 'Responsabilidades asignadas exitosamente.';
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response?.data?.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.loading = false;
        });
    },

    pushSelectedEmisions() {
      console.log(this.selectedEmisions);
      this.emisionsForm = [];

      this.selectedEmisions.forEach((id) => {
        if (!this.emisionsForm.includes(id)) {
          this.emisionsForm.push(id);
        }
      });
    },
    //DESCOMENTAR EN DADO CASO QUE SE REQUIERA ENVIAR EL OBJETO DE FUENTE DE EMISION CON SU ID Y NOMBRE.
    // pushSelectedEmisions() {
    //   this.emisionsForm = [];
    //   this.selectedEmisions.forEach((id) => {
    //     const emissionObject = this.fuentesEmision.find((emision) => emision.idfuente_emision === id);

    //     if (emissionObject && !this.emisionsForm.some((emision) => emision.idfuente_emision === id)) {
    //       this.emisionsForm.push(emissionObject);
    //     }
    //   });
    // },

    // pushSelectedLocations() {
    //   this.locationsForm = [];
    //   this.selectedLocations.forEach((locationF) => {
    //     const locationsObject = this.forms.responsibilities.locations.find((location) => locationF === location);

    //     if (locationsObject && !this.locationsForm.some((location) => locationF === location)) {
    //       this.locationsForm.push(locationsObject);
    //     }
    //   });
    // },

    async searchResponsibilitiesByLocation(item) {
      let userId = this.forms.responsibilities.detalles_usuarios.idUsuario;
      let companyId = this.companyId;
      let selectedLocation = this.selectedLocations[0];
      this.selectedEmisions = [];
      console.log('Se busco por sucursal', selectedLocation);

      this.axios
        .get(services.routes.company)
        .then((response) => {
          const filteredItem = response.data.data.filter((item) => item.idempresa == companyId);
          let fuenteEmpresas = { ...filteredItem[0].fuentes_empresas };

          if (!Array.isArray(fuenteEmpresas)) {
            fuenteEmpresas = Array.isArray(fuenteEmpresas) ? fuenteEmpresas : Object.values(fuenteEmpresas);
          }

          let fuentesEmision = fuenteEmpresas.map((fuente) => {
            return { idfuente_emision: fuente.fuente_emision.idfuente_emision, nombre: fuente.fuente_emision.nombre };
          });

          this.fuentesEmision = fuentesEmision;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });

      this.axios
        .get(services.routes.responsibilities, {
          params: {
            idUsuario: userId,
            idEmpresa: companyId,
            locacion: selectedLocation
          }
        })
        .then((response) => {
          console.log(response.data);

          this.msgSuccess = 'Responsabilidades asignadas exitosamente.';

          if (response.data.length > 0) {
            this.emissionsAlreadyAssigned = true;
            let tipo;
            response.data[0].tipo == false ? (tipo = '0') : (tipo = '1');
            this.forms.responsibilities.type = tipo;

            this.selectedEmisions = [];

            response.data.forEach((register) => {
              this.selectedEmisions.push(register.idfuente_emision);
            });
            this.pushSelectedEmisions();
          } else {
            this.emissionsAlreadyAssigned = false;
          }
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          // this.loading = false;
        });
    },

    async searchSucursal(item) {
      this.companyId = item;
      this.selectedEmisions = [];
      this.selectedLocations = [];
      this.forms.responsibilities.locations = [];
      // let companyId = item;

      //services.routes.catalogue + '/detail?id=' + data.idcatalogo
      await this.axios.get(services.routes.catalogue + `/idempresa?id=${this.forms.responsibilities.companySelected}`).then((res) => {
        let code = res.data.data.find((s) => s.codigo == 'UBICACION');
        this.forms.responsibilities.locations = code.valores;
      });
      const source = await this.axios.get(services.routes.emissionSource);
    }
  }
};
</script>

<style scoped>
.fixed-height {
  max-height: 25px;
  flex: 0 1 auto;
}

.fixed-height .v-input__control {
  max-height: 20px;
  overflow: hidden;
}

.fixed-height .v-select__selections {
  max-height: 20px;
  max-width: 290px !important;
  overflow: hidden;
  /* display: flex; */

  wrap: none !important;
  align-items: center;
}

.fixed-height .v-input__slot {
  height: 20px;
  overflow: hidden;
}

.fixed-height ::v-deep .v-select__slot {
  width: 265px !important;
  max-width: 350px !important;
  /* max-height: 40px !important; */
}

.fixed-height ::v-deep .v-select__selections {
  flex-wrap: nowrap !important;
  /* max-height: 40px !important; */
}
</style>
